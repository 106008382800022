import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Spinner from 'src/components/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import { setLoading } from 'src/redux/user/user.actions';
import TaskListToolbar from 'src/components/task/TaskListToolbar';
import {
  addAssignment,
  calcScore,
  getAssignments,
  getTasks
} from 'src/redux/task/task.actions';
import { getClients } from 'src/redux/client/client.actions';
import TaskResults from 'src/components/task/TaskResults';
import { addTask } from 'src/redux/task/task.actions';
import { getClientUsers } from 'src/redux/client-users/clientuser.actions';

const Tasks = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const errorState = useSelector((state) => state.errors);
  const showLoading = useSelector((state) => state.user.loading);
  const clientsState = useSelector((state) => state.client.clients);
  const tasksState = useSelector((state) => state.task.tasks);
  const clientUserssState = useSelector(
    (state) => state.clientuser.clientusers
  );
  const [tasksLocalState, setTasksLocalState] = useState(tasksState);
  const [clientsLocalState, setClientsLocalState] = useState(clientsState);
  const [isAddTask, setIsAddTask] = useState(false);
  const [clientUsers, setClientUsers] = useState(clientUserssState);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedAssessmentType, setSelectedAssessmentType] = useState(null);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);
  const [selectedAssessor, setSelectedAssessor] = useState(null);
  const [selectedRelation, setSelectedRelation] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleAssesorSubmit = () => {
    if (
      selectedTask != null &&
      selectedAssessor != null &&
      selectedRelation != null
    ) {
      let assignmentModel = {
        taskId: selectedTask.task_id,
        assessorId: selectedAssessor,
        relation: selectedRelation,
        assignmentType: selectedTask.assignment_type,
        clientId: selectedClient
      };
      dispatch(setLoading(true));
      dispatch(addAssignment(assignmentModel));
      dispatch(setLoading(false));
      setSelectedTask(null);
      setSelectedAssessor(null);
      setSelectedRelation(null);
      setShowAssignmentModal(false);
    }
  };

  const receiveTaskClick = (task) => {
    if (task != undefined) {
      dispatch(setLoading(true));
      dispatch(getAssignments({ taskId: task.task_id }));
      dispatch(setLoading(false));
    }
  };

  const receiveAssign = (task) => {
    setSelectedTask(task);
    setShowAssignmentModal(true);
  };

  const receiveRefresh = () => {
    if (selectedClient != null) {
      dispatch(setLoading(true));
      dispatch(getTasks({ clientId: selectedClient }));
      dispatch(setLoading(false));
    }
  };

  const calculateScores = (scoreInfo) => {
    try {
      dispatch(setLoading(true));
      let scoreObj = {
        taskId: scoreInfo.task_id,
        taskType: scoreInfo.assignment_type,
        clientId: selectedClient
      };
      dispatch(calcScore(scoreObj));
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isAddTask || showAssignmentModal) {
      dispatch(setLoading(true));
      dispatch(getClientUsers({ clientId: selectedClient }));
      dispatch(setLoading(false));
    }
  }, [isAddTask, showAssignmentModal]);

  useEffect(() => {
    if (selectedClient != null) {
      dispatch(setLoading(true));
      dispatch(getTasks({ clientId: selectedClient }));
      dispatch(setLoading(false));
    }
  }, [selectedClient]);

  useEffect(() => {
    setClientsLocalState(clientsState);
  }, [clientsState]);

  useEffect(() => {
    setClientUsers(clientUserssState);
  }, [clientUserssState]);

  useEffect(() => {
    setTasksLocalState(tasksState);
  }, [tasksState]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getClients({ email: user.email }));
    dispatch(setLoading(false));
  }, []);

  return (
    <>
      <Helmet>
        <title>Assessment</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Toaster position="top-center" reverseOrder={false} />
          <Spinner showLoading={showLoading} />
          <Dialog
            open={showAssignmentModal}
            onClose={() => {}}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">Assign Assessor</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Select Assessor and their Relation to the pariticipant from the
                list
              </DialogContentText>
              <InputLabel
                style={{ marginLeft: 5, fontSize: 12 }}
                htmlFor="Select Assessor"
              >
                Select Assessor
              </InputLabel>
              <Select
                fullWidth
                onChange={(event) => {
                  setSelectedAssessor(event.target.value);
                }}
                required
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {clientUsers.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {`${option.first_name} ${option.last_name}`}
                  </MenuItem>
                ))}
              </Select>
              <InputLabel
                style={{ marginLeft: 5, marginTop: 15, fontSize: 12 }}
                htmlFor="Select Relation"
              >
                Select Relation
              </InputLabel>
              <Select
                fullWidth
                onChange={(event) => {
                  setSelectedRelation(event.target.value);
                }}
                required
                SelectProps={{ native: true }}
                variant="outlined"
              >
                <MenuItem value="Peer">Peer</MenuItem>
                <MenuItem value="IO">Manager</MenuItem>
                <MenuItem value="SubOrdinate">SubOrdinate</MenuItem>
                <MenuItem value="Self">Self</MenuItem>
                <MenuItem value="PA_Self">PA_Self</MenuItem>
              </Select>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowAssignmentModal(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleAssesorSubmit} color="primary">
                ASSIGN
              </Button>
            </DialogActions>
          </Dialog>
          {!isAddTask ? (
            <>
              <TaskListToolbar
                clients={clientsLocalState}
                handleAddClick={() => setIsAddTask(true)}
                handleRefresh={receiveRefresh}
                handleClientChange={(val) => setSelectedClient(val)}
              />
              <Box sx={{ pt: 3 }}>
                <TaskResults
                  tasks={tasksLocalState}
                  handleTaskClick={receiveTaskClick}
                  handleAssign={receiveAssign}
                  handleCalculate={calculateScores}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ pt: 3 }}>
                <Grid item lg={12} md={12} xs={12}>
                  <Card mx={2}>
                    <CardHeader
                      subheader="Create new assessment"
                      title="Assessment"
                    />
                    <Divider />
                    <CardContent>
                      <Formik
                        initialValues={{}}
                        onSubmit={(values) => {
                          values.clientId = selectedClient;
                          values.participantId = selectedParticipant;
                          values.assignedById = user.id;
                          values.assignmentType = selectedAssessmentType;
                          dispatch(addTask(values))
                            .then(() => {
                              setIsAddTask(false);
                              toast.success(
                                'Assessment record got saved successfully'
                              );
                              setSelectedParticipant(null);
                              setSelectedAssessmentType(null);
                            })
                            .catch((err) => {});
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleSubmit,
                          handleChange,
                          touched,
                          values
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              <Grid item md={6} xs={12}>
                                <InputLabel
                                  style={{ marginLeft: 5, fontSize: 12 }}
                                  htmlFor="Select Participant"
                                >
                                  Select Participant
                                </InputLabel>
                                <Select
                                  fullWidth
                                  onChange={(event) => {
                                    setSelectedParticipant(event.target.value);
                                  }}
                                  required
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                >
                                  {clientUsers.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {`${option.first_name} ${option.last_name}`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <InputLabel
                                  style={{ marginLeft: 5, fontSize: 12 }}
                                  htmlFor="Assessment Type"
                                >
                                  Assessment Type
                                </InputLabel>
                                <Select
                                  fullWidth
                                  onChange={(event) => {
                                    setSelectedAssessmentType(
                                      event.target.value
                                    );
                                  }}
                                  required
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                >
                                  <MenuItem value="NESW">360°</MenuItem>
                                  <MenuItem value="PA">PA</MenuItem>
                                  <MenuItem value="TEAM_CULTURE">
                                    Team Culture
                                  </MenuItem>
                                </Select>
                              </Grid>
                            </Grid>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 2
                              }}
                            >
                              <Button
                                onClick={() => setIsAddTask(false)}
                                color="secondary"
                                size="large"
                                type="submit"
                                variant="contained"
                              >
                                CANCEL
                              </Button>
                              <Button
                                color="primary"
                                size="large"
                                type="submit"
                                variant="contained"
                              >
                                SAVE
                              </Button>
                            </Box>
                            <Typography
                              color="red"
                              variant="body1"
                              align="center"
                            >
                              {errorState &&
                                errorState.info &&
                                errorState.info.msg}
                            </Typography>
                          </form>
                        )}
                      </Formik>
                    </CardContent>
                  </Card>
                </Grid>
              </Box>
            </>
          )}
          <Typography color="red" variant="body1" align="center">
            {errorState && errorState.info && errorState.info.msg}
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Tasks;
