import { useState } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Button,
  Typography,
  Collapse,
  IconButton
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from '@material-ui/icons/Edit';

const TaskResults = (props) => {
  const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset'
      }
    }
  });
  const classes = useRowStyles();
  const tasks = _.sortBy(props.tasks, [
    function (o) {
      return o.assignment_type;
    }
  ]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(true);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card>
      <CardHeader
        action={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            DOWNLOAD AS EXCEL
          </Button>
        }
        title="Assessments"
      />
      <Divider />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox"></TableCell>
                <TableCell>Participant Name</TableCell>
                {/* <TableCell>Name</TableCell> */}
                <TableCell>Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
                <TableCell>Calc Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <>
                  <TableRow className={classes.root} key={task.id}>
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => props.handleTaskClick(task)}
                      >
                        {task.assignments.length > 0 ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                  {row.name}
                </TableCell> */}
                    <TableCell>{`${task.firstname} ${task.lastname}`}</TableCell>
                    {/* <TableCell>{task.name}</TableCell> */}
                    <TableCell>
                      {task.assignment_type == 'NESW'
                        ? '360'
                        : task.assignment_type == 'TEAM_CULTURE'
                        ? 'Team Culture'
                        : 'PA'}
                    </TableCell>
                    <TableCell>
                      <Typography
                        color="primary"
                        variant="body2"
                        fontWeight="bold"
                      >
                        {task.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        size="small"
                        onClick={() => props.handleAssign(task)}
                      >
                        ASSIGN
                      </Button>
                    </TableCell>
                    {task.assignment_type == 'NESW' && (
                      <TableCell>
                        <Button
                          color="secondary"
                          size="small"
                          variant="contained"
                          onClick={() => props.handleCalculate(task)}
                        >
                          CALCULATE
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={task.assignments.length > 0 ? true : false}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Box
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              component="div"
                              color="primary"
                            >
                              Assessment Details
                            </Typography>
                          </Box>
                          <Table size="small" aria-label="assessmentDetails">
                            <TableHead>
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Assessor</TableCell>
                                <TableCell>Relation</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {task.assignments.map((assignment) => (
                                <TableRow key={assignment.id}>
                                  <TableCell></TableCell>
                                  <TableCell component="th" scope="row">
                                    {`${assignment.assessor_first_name} ${assignment.assessor_last_name}`}
                                  </TableCell>
                                  <TableCell>{assignment.relation}</TableCell>
                                  <TableCell color="primary">
                                    <Typography
                                      color={
                                        assignment.status == 'Pending'
                                          ? 'secondary'
                                          : 'textPrimary'
                                      }
                                      variant="body2"
                                      fontWeight="bold"
                                    >
                                      {assignment.status}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <IconButton>
                                      <EditIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default TaskResults;
