/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography
} from '@material-ui/core';
import toast, { Toaster } from 'react-hot-toast';
import * as AssessmentService from 'src/services/AssessmentService';
import { setLoading } from 'src/redux/user/user.actions';
import { setType } from 'src/redux/assessment/assessment.action';
import Instructions from 'src/components/assessment/Instructions';
import PA_Instructions from 'src/components/assessment/PA_Instructions';
import Questionnaire from 'src/components/assessment/Questionnaire';
import PA_Questionnaire from 'src/components/assessment/PA_Questionnaire';
import TC_Instructions from 'src/components/assessment/TC_Instructions';
import TC_Questionnaire from 'src/components/assessment/TC_Questionnaire';
import Spinner from 'src/components/Spinner';

const Assessment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const type = useSelector((state) => state.assessment.type);
  const errorState = useSelector((state) => state.errors);
  const showLoading = useSelector((state) => state.user.loading);
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [isStart, setIsStart] = useState(false);
  const [submittedFlag, setSubmittedFlag] = useState(false);

  const receiveSubmit = () => {
    setSubmittedFlag(true);
  };

  const receiveStart = () => {
    setIsStart(true);
  };

  useEffect(() => {
    if (errorState && errorState.info && errorState.info.msg) {
      toast.error(errorState.info.msg);
    }
  }, [errorState]);

  useEffect(() => {
    (async () => {
      try {
        const assignmentid = window.location.pathname.split('/').pop();
        dispatch(setLoading(true));
        const questionRes = await AssessmentService.fetchQuestions(
          assignmentid
        );
        if (
          questionRes &&
          questionRes.questions &&
          questionRes.questions.length > 0
        ) {
          dispatch(setType(questionRes.questions[0].question_type));
          setQuestionList(questionRes.questions);
        }
        dispatch(setLoading(false));
      } catch (err) {
        dispatch(setLoading(false));
      }
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>Zygote Assessment</title>
      </Helmet>
      <Toaster position="top-center" reverseOrder={false} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
          justifyContent: 'center',
          marginTop: 3
        }}
      >
        <Container maxWidth="lg">
          {questionList.length > 0 && !submittedFlag && (
            <>
              <>
                {type == 'NESW' ? (
                  <>
                    {!isStart && (
                      <Instructions
                        participantname={
                          questionList.length > 0
                            ? questionList[0].participant_first_name +
                              ' ' +
                              questionList[0].participant_last_name
                            : '--'
                        }
                        assessorname={
                          questionList.length > 0
                            ? questionList[0].assessor_first_name +
                              ' ' +
                              questionList[0].assessor_last_name
                            : '--'
                        }
                        relation={
                          questionList.length > 0
                            ? questionList[0].relation
                            : '--'
                        }
                        handleStartClick={receiveStart}
                        assessmentType={type}
                      />
                    )}
                    {isStart && (
                      <Questionnaire
                        questionList={questionList}
                        handleSubmit={receiveSubmit}
                        assessmentType={type}
                        relation={
                          questionList.length > 0
                            ? questionList[0].relation
                            : '--'
                        }
                        participantname={
                          questionList.length > 0
                            ? questionList[0].participant_first_name +
                              ' ' +
                              questionList[0].participant_last_name
                            : '--'
                        }
                        assessorname={
                          questionList.length > 0
                            ? questionList[0].assessor_first_name +
                              ' ' +
                              questionList[0].assessor_last_name
                            : '--'
                        }
                      />
                    )}
                  </>
                ) : type == 'TEAM_CULTURE' ? (
                  <>
                    {!isStart && (
                      <TC_Instructions
                        participantname={
                          questionList.length > 0
                            ? questionList[0].participant_first_name +
                              ' ' +
                              questionList[0].participant_last_name
                            : '--'
                        }
                        assessorname={
                          questionList.length > 0
                            ? questionList[0].assessor_first_name +
                              ' ' +
                              questionList[0].assessor_last_name
                            : '--'
                        }
                        relation={
                          questionList.length > 0
                            ? questionList[0].relation
                            : '--'
                        }
                        handleStartClick={receiveStart}
                        assessmentType={type}
                      />
                    )}
                    {isStart && (
                      <TC_Questionnaire
                        participantname={
                          questionList.length > 0
                            ? questionList[0].participant_first_name +
                              ' ' +
                              questionList[0].participant_last_name
                            : '--'
                        }
                        questionList={questionList}
                        handleSubmit={receiveSubmit}
                        assessmentType={type}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {!isStart && (
                      <PA_Instructions
                        participantname={
                          questionList.length > 0
                            ? questionList[0].participant_first_name +
                              ' ' +
                              questionList[0].participant_last_name
                            : '--'
                        }
                        assessorname={
                          questionList.length > 0
                            ? questionList[0].assessor_first_name +
                              ' ' +
                              questionList[0].assessor_last_name
                            : '--'
                        }
                        relation={
                          questionList.length > 0
                            ? questionList[0].relation
                            : '--'
                        }
                        handleStartClick={receiveStart}
                        assessmentType={type}
                      />
                    )}
                    {isStart && (
                      <PA_Questionnaire
                        participantname={
                          questionList.length > 0
                            ? questionList[0].participant_first_name +
                              ' ' +
                              questionList[0].participant_last_name
                            : '--'
                        }
                        questionList={questionList}
                        handleSubmit={receiveSubmit}
                        assessmentType={type}
                      />
                    )}
                  </>
                )}
              </>
            </>
          )}
          {submittedFlag && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: '10%'
              }}
            >
              <img alt="Logo" width={130} height={100} src="/static/logo.png" />
              <Typography
                color="primary"
                fontFamily="Raleway"
                fontWeight="bold"
                variant="h3"
                marginTop={3}
              >
                Thank you for participating in the assessment
              </Typography>
            </div>
          )}
          {questionList.length == 0 && !showLoading && !submittedFlag && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                marginTop: '10%'
              }}
            >
              <img alt="Logo" width={130} height={100} src="/static/logo.png" />
              <Typography
                color="primary"
                fontFamily="Raleway"
                fontWeight="bold"
                variant="h3"
                marginTop={3}
              >
                The assessment you are trying to access is not available
              </Typography>
            </div>
          )}
          <Spinner showLoading={showLoading} />
        </Container>
      </Box>
    </>
  );
};

export default Assessment;
